import dragdrop from './exercises/DragDrop';
import dropDown from './exercises/DropDown';
import listen from './exercises/Listen';
import match from './exercises/Match';
import multipleChoice from './exercises/MultipleChoice';
import multipleMatch from './exercises/MultipleMatch';
import oneChoice from './exercises/OneChoice';
import selectWord from './exercises/SelectWord';
import write from './exercises/Write';
import speechRecognition from './exercises/SpeechRecognition';
import record from './exercises/Record';

export default {
  dragdrop,
  dropDown,
  listen,
  match,
  multipleChoice,
  multipleMatch,
  oneChoice,
  selectWord,
  write,
  speechRecognition,
  record,
};
