// let APPLICATION_NAME = 'VIMARU';
let APPLICATION_NAME = 'QUIZCITY';

// let MODULE_ID;
let MODULE_ID = '00000000-0000-0000-0000-000000000000';

//DOMAIN của code đang chạy
const DOMAIN_IS_USE = `${window.location.protocol}//${window.location.hostname}${
  window.location.port ? `:${window.location.port}` : ''
}`;

// Notification
const urlSendNotification = 'https://fcm.googleapis.com/fcm/send';
const serverKeyNotification =
  'key=AAAA4gpWDCc:APA91bEFvJE3fzk-5tGARYtjaq_66cjzcxWnSsr0wX6-uO8v8zF3xkleZYXGkxkCOGOHKDNj6aGrsMEkZMyi0gtfuGKlqqiYfU6DsgK0gHjz-a5HExJPsrj7V5QIzni-9goMM3YkecDA';
const vapidKey = 'BGVbMT-2SJBKqF-spcp6nt-dHdigYQgvcg6nhDhrNDbAMNIcuyZ1wq8OcvhInRwMFB-u-USlV2YBwRuZQ6W2sBE';

const IS_MENARD = window.location.hostname.includes('menard');
const IS_VIMARU = window.location.hostname.includes('vimaru');
const IS_HCM = window.location.hostname.includes('hcm');
const IS_CDP = window.location.hostname.includes('cdp') || window.location.hostname.includes('cpd');
const IS_AMES =
  window.location.hostname.includes('ames.englishcity') ||
  window.location.hostname.includes('quiz-city-project') ||
  window.location.hostname.includes('lms.ames.vn');
const IS_AMES_QUIZCITY =
  window.location.hostname.includes('ames.quizcity.io') || window.location.hostname.includes('ames-quizcity');
const IS_EITHER_AMES = IS_AMES || IS_AMES_QUIZCITY;
const IS_LG = window.location.hostname.includes('lginnotek');
const IS_ENGLISHCITY = window.location.hostname === 'englishcity.vn';
const IS_QUIZCITY = window.location.hostname === 'quizcity.io';
const IS_TVHH = window.location.hostname.includes('thuyenvienhh');
const IS_ISE = window.location.hostname.includes('ise');
const IS_BASAO = window.location.hostname.includes('basao');
const IS_TRONGDONG = window.location.hostname.includes('trongdong');
const IS_LX = window.location.hostname.includes('lx');
let IS_HIGHUP = false;
let IS_HANDOUT = false;
let IS_PT = false;
let PATHNAME = 'lms';

if (window.location.hostname.includes('preview')) {
  APPLICATION_NAME = 'QUIZCITY_STAGE';
} else if (window.location.hostname.includes('localhost')) {
  APPLICATION_NAME = 'QUIZCITY_DEV';
}

if (IS_MENARD) {
  MODULE_ID = '0CDD3C5D-0BCE-49F2-91EF-DDE9AD0CEF34';
} else if (IS_VIMARU) {
  MODULE_ID = '7a84fd5e-4dc1-491e-9c3b-89218b1d702f';
} else if (IS_HCM) {
  MODULE_ID = 'C5CDBDA6-035C-47CB-8340-D7B59A68D07C';
} else if (IS_AMES) {
  const subDomainArray = window.location.pathname.split('/');
  const subDomain = subDomainArray[1] === 'auth' ? subDomainArray[2] : subDomainArray[1];
  if (subDomain?.toUpperCase() === 'HANDOUT') {
    IS_HANDOUT = true;
    PATHNAME = 'handout';
    MODULE_ID = '5F2FCA5C-7159-46D6-B08A-3D2E3331280E';
  } else if (subDomain?.toUpperCase() === 'PT') {
    IS_PT = true;
    PATHNAME = 'pt';
    MODULE_ID = 'CA45E8D3-0B49-4284-88EA-AA3E230E1886';
  } else {
    IS_HIGHUP = true;
    MODULE_ID = '92317154-B6B0-4BE5-A097-C3779021B2A4';
  }
} else if (IS_ENGLISHCITY) {
  MODULE_ID = 'CA6E9311-6305-4F62-ABBA-F3671FBAFF5E';
} else if (IS_QUIZCITY) {
  MODULE_ID = '9F36C177-DFB8-407C-975F-338838B2B6C9';
} else if (IS_AMES_QUIZCITY) {
  MODULE_ID = '666F5FC9-7148-4AF8-BA04-4D9B81505398';
} else if (IS_LG) {
  PATHNAME = 'lginnotek';
  MODULE_ID = '2FAE24D0-E489-422F-BC5E-B35DF08AEE12';
} else if (IS_CDP) {
  MODULE_ID = 'DD2D865F-32DD-4F9E-B850-66670183C97A';
} else if (IS_TVHH) {
  PATHNAME = 'tvhh';
  MODULE_ID = 'F9810BCC-1FB2-4496-BAE0-F8BFB844878B';
} else if (IS_ISE) {
  PATHNAME = 'ise';
  MODULE_ID = 'BD892DBE-FDE4-4720-B881-813B33BE1F3F';
} else if (IS_BASAO) {
  // PATHNAME = 'basao';
  MODULE_ID = '2A3DCE81-0925-40DF-A6AE-80BFCEB8C07E';
} else if(IS_TRONGDONG){
  MODULE_ID = '15A8C18C-BC0E-40EA-B760-4F19D98F4B13'
} else if(IS_LX){
  MODULE_ID = '4AA72888-1D1B-4423-A434-F938B103BB7F'
}

const studentPronouns2 =
  IS_VIMARU || IS_HIGHUP
    ? 'Sinh viên'
    : IS_PT || IS_HANDOUT
    ? 'Học sinh'
    : IS_LG || IS_ISE || IS_TVHH
    ? 'Student'
    : 'Học viên';
const studentPronouns1 = studentPronouns2.toLowerCase();

export {
  APPLICATION_NAME,
  DOMAIN_IS_USE,
  IS_AMES,
  IS_AMES_QUIZCITY,
  IS_CDP,
  IS_EITHER_AMES,
  IS_HANDOUT,
  IS_HCM,
  IS_HIGHUP,
  IS_ISE,
  IS_LG,
  IS_MENARD,
  IS_PT,
  IS_QUIZCITY,
  IS_TVHH,
  IS_VIMARU,
  IS_BASAO,
  IS_TRONGDONG,
  IS_LX,
  MODULE_ID,
  PATHNAME,
  serverKeyNotification,
  studentPronouns1,
  studentPronouns2,
  urlSendNotification,
  vapidKey,
};

//Không sử dụng export default nữa
export default {
  APPLICATION_NAME,
  IS_MENARD,
  IS_VIMARU,
  IS_PT,
  IS_HIGHUP,
  MODULE_ID,
  IS_AMES,
  IS_QUIZCITY,
  IS_HANDOUT,
  IS_HCM,
  IS_CDP,
  IS_EITHER_AMES,
  IS_AMES_QUIZCITY,
  IS_LG,
  IS_TVHH,
  IS_ISE,
  serverKeyNotification,
  urlSendNotification,
  vapidKey,
  PATHNAME,
  DOMAIN_IS_USE,
  studentPronouns1,
  studentPronouns2,
};


