export const modeName = {
  WRITE: 'Write',
  CHOICE: 'Choice',
  MATCH: 'Match',
  MULTIPLEMATCH: 'MultipleMatch',
  SELECTWORD: 'SelectWord',
  LISTEN: 'Listen',
  DRAGDROP: 'DragDrop',
  DROPDOWN: 'DropDown',
  SPEECHRECOGNITION: 'SpeechRecognition',
  RECORD: 'Record',
};
