/* eslint-disable no-unused-expressions */
/* eslint-disable no-case-declarations */
import React, { useCallback, useEffect, useRef, useState } from 'react';
// import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { get, query } from 'helpers/QueryHelper';
import { cdnServerUrl } from 'constants/serverUrls';
import { updateJsonData } from './api';
import {
  addRect,
  canvasID,
  canvasToJson,
  configDeleteControl,
  configCopyControl,
  createCanvas,
  createExerciseData,
  keyToolbar,
  modeName,
} from './configuration';
import EditorElement from './EditorElement';
import Mouse from './models/mouse';
import ToolBar from './ToolBar';
import UploadFile from './UploadFile';
import { Link } from 'react-router-dom';
import settings from 'constants/settings';
import { useSelector } from 'react-redux';
// import { Button, Col, Input, Row } from 'antd';
// import { EditFilled } from '@ant-design/icons';

const { DRAGDROP } = modeName;

const { SAVE, REMOVEALL, CANCEL, DONE, CHANGEMODE } = keyToolbar;
const getDataByID = (attachmentId, subModuleName, entityName) => {
  if (!attachmentId) return [];
  return get(
    'Attachments',
    '*',
    { id: attachmentId, entityName: `t_SACHSO_${subModuleName}_${entityName}` },
    'CreatedDate DESC',
    'SHARE',
  ).then((res) => res[0]);
};

// Trong module TeacherModul, File được tạo từ MYAMES, nên sách số phải gọi qua bên đó lấy dữ liệu.
const getDataByIDFromMyAMES = (attachmentsId) => {
  if (!attachmentsId) return [];
  return get(
    'Attachments',
    '*',
    { id: attachmentsId, entityName: 't_AMES247_Sessions' },
    'CreatedDate DESC',
    'SHARE',
    'MYAMES',
    'SmartEducation',
  ).then((res) => res[0]);
};

// useMyAMESData: cho phần quản lý bài tập, dữ liễu được lấy từ bên đó về.
function CanvasToolPage({
  idCurrent,
  entityId,
  subModuleName = 'CLASSWORK',
  entityName = 'Assignments',
  mode,
  worksheetFile,
  teacherId,
  onUpdateImage,
  useMyAMESData,
}) {
  const [storeCanvas, setStoreCanvas] = useState({});
  const [canvas, setCanvas] = useState();
  //
  const refMouse = useRef(new Mouse());
  // const refLinkTo = useRef({});
  const refToolBar = useRef({});
  const refEditorElement = useRef({});

  const loggedInUser = useSelector((rootState) => rootState.authReducer.loggedInUser);

  // init
  useEffect(() => {
    const loadData = async () => {
      if (!idCurrent && mode === 'create') {
        setStoreCanvas((pre) => ({
          ...pre,
          backgroundImage: '',
          canvasJson: '',
        }));
        return;
      } else if ((mode = 'edit')) {
        const dataByID = useMyAMESData
          ? await getDataByIDFromMyAMES(idCurrent)
          : await getDataByID(idCurrent, subModuleName, entityName);

        if (dataByID?.id) {
          const { id, fileUrl, jsonData } = dataByID;
          setStoreCanvas((pre) => {
            return { ...pre, id, backgroundImage: `${cdnServerUrl}${fileUrl}`, canvasJson: jsonData?.canvasJson };
          });
        }
      }
    };
    //
    loadData();
  }, [mode, idCurrent]);

  //
  useEffect(() => {
    const { backgroundImage, canvasJson } = storeCanvas;
    const canvi = createCanvas(backgroundImage, canvasJson);
    //
    setCanvas(canvi);
    configDeleteControl();
    configCopyControl();

    return () => {
      if (canvi) {
        canvi.dispose();
      }
    };
  }, [storeCanvas]);
  // canvas event
  useEffect(() => {
    const toolBar = refToolBar.current;
    const mouse = refMouse.current;
    if (typeof toolBar.getMode !== 'function') return undefined;
    // On: để khởi tạo lắng nghe sự kiện
    canvas?.on({
      // Chuột được nhấn
      'mouse:down': (e) => {
        mouse.eventDown(e);
      },
      // Chuột được thả
      'mouse:up': (e) => {
        const up = mouse.eventUp(e);
        const down = mouse.eventDown();
        const countActiveObjects = canvas.getActiveObjects().length;
        //
        if (!(down.target || up.target || countActiveObjects > 0)) {
          addRect(canvas, mouse, toolBar);
        }
      },
      'mouse:out': (e) => {
        if (e.target === null && toolBar.getMode() === '') {
          //
          // handleSave();
        }
      },
      'object:modified': () => {
        if (toolBar.getMode() === '') {
          handleSave();
        }
      },
      // Khi canvas được re-render
      'after:render': () => {
        //
        const arrayObject = canvas.getObjects('rect');
        refEditorElement.current.setList(arrayObject);
      },
    });
    return () => {
      // Xóa khởi tạo lắng nghe sự kiện
      // removes all handlers for all events
      canvas?.off();
    };
  }, [canvas]);
  //
  const handleChangeImage = useCallback(
    ({ imgURL, id }) => {
      // update new image and delete old canvas
      setStoreCanvas({ backgroundImage: imgURL, id });
      //
      if (typeof onUpdateImage === 'function') {
        onUpdateImage(id);
      }
    },
    [onUpdateImage],
  );
  //
  const handleSave = useCallback(() => {
    // save and send data
    const canvasJson = canvasToJson(canvas);
    const ExerciseData = createExerciseData(canvasJson.objects);
    const newStoreCanvas = { ...storeCanvas, canvasJson, ExerciseData };
    // send
    const data = JSON.stringify(newStoreCanvas);

    const TotalQuestion = newStoreCanvas?.ExerciseData?.length;
    localStorage.setItem('storeCanvas', data);
    updateJsonData(worksheetFile ? worksheetFile.id : storeCanvas.id, data, TotalQuestion, loggedInUser?.id).then(
      (response) => {},
    );
    // query('p_SACHSO_SHARE_Attachments_UpdateModule', {
    //   Id: id,
    //   ModuleId: settings.MODULE_ID,
    // });
  }, [canvas, storeCanvas, teacherId, worksheetFile]);
  //
  const handleClickToolBar = useCallback(
    (key) => {
      const toolBar = refToolBar.current;
      switch (key) {
        case CANCEL:
          // delete objects belonging to the current group
          const groupCurrent = toolBar.getGroupName();
          const Objects = canvas.getObjects().filter((x) => x.data.groupName === groupCurrent);
          canvas.remove(...Objects);
          break;
        case DONE:
          handleSave();
          break;
        case CHANGEMODE:
          const groupMax = Math.max(...canvas.getObjects().map((x) => x.data.groupName), 0);
          toolBar.setGroupName(groupMax + 1);
          break;
        case REMOVEALL:
          canvas.remove(...canvas.getObjects());
          break;
        case SAVE:
          handleSave();
          break;

        default:
          break;
      }
    },
    [canvas, handleSave],
  );
  //
  return (
    <div className='tw-h-full'>
      <div style={{ display: 'flex', marginBottom: 10 }}>
        {!idCurrent && (
          // <Row gutter={[12, 12]}>
          //   <Col lg={8}>
          <div className='mr-2'>
            <UploadFile
              entityId={entityId}
              onChange={handleChangeImage}
              userId={teacherId}
              subModuleName={subModuleName}
              entityName={entityName}
            />
          </div>
          //   </Col>
          //   <Col lg={8}>
          //     <Button ghost style={{ backgroundColor: '#1890ff', color: 'white' }} icon={<EditFilled />}>
          //       Tải Video
          //     </Button>
          //   </Col>
          //   <Col lg={8}>
          //     <Input style={{ width: '100%' }} placeholder='Đường dẫn video' />
          //   </Col>
          // </Row>
        )}
        {storeCanvas.backgroundImage && <ToolBar refToolBar={refToolBar} onClickToolBar={handleClickToolBar} />}
      </div>
      <div style={{ maxHeight: '90%', overflow: 'auto' }}>
        <div style={{ position: 'relative' }}>
          <canvas id={canvasID} />
          <EditorElement refEditorElement={refEditorElement} canvas={canvas} />
        </div>
      </div>
      {/* <Link ref={refLinkTo} to='/lms/ExcercisePage' style={{ display: 'none' }}>
        link
      </Link> */}
    </div>
  );
}

CanvasToolPage.propTypes = {
  entityId: PropTypes.string,
  worksheetFile: PropTypes.object,
  teacherId: PropTypes.string | PropTypes.number,
  idCurrent: PropTypes.string,
  onUpdateImage: PropTypes.func,
  subModuleName: PropTypes.string,
  entityName: PropTypes.string,
  useMyAMESData: PropTypes.bool,
};

CanvasToolPage.defaultProps = {
  entityId: '',
  worksheetFile: {},
  teacherId: PropTypes.number,
  idCurrent: '',
  onUpdateImage: PropTypes.func,
  subModuleName: 'CLASSWORK',
  entityName: 'Assignments',
  useMyAMESData: false,
};

export default CanvasToolPage;
