/* eslint-disable react/jsx-props-no-spreading */
import { Button, Form, Input, message, notification } from 'antd';
// core components
import AuthHeader from 'components/Headers/AuthHeader';
import colors from 'constants/colors';
import { generateSHA1 } from 'helpers/CryptoHelper';
import { amesSendMail, query } from 'helpers/QueryHelper';
import { GenPasswordModel } from 'modules/Presentation/Viewer/components/Model';
import PropTypes from 'prop-types';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import settings from 'constants/settings';
import i18next from 'i18next';
import moment from 'moment';
import getSettings from 'pages/Administration/api/getSettings';
import { useQuery } from 'react-query';
import { Card, CardBody, CardHeader, Col, Container, Modal, Row } from 'reactstrap';
import RegisterStudentPopup from './RegisterStudentPopup';

const FormItem = Form.Item;

const logoUrl =
  window.location.hostname === 'quizcity.io'
    ? require('assets/img/brand/logo-quizcity.png').default
    : require('assets/img/brand/english-city-01.png').default;
const SetModuleLogin = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [isStatusFormForgotPassword, setStatusFormForgotPassword] = React.useState(false);
  const loggedInUser = useSelector((userState) => userState.authReducer.loggedInUser);
  const [modalStudentVisible, setModalStudentVisible] = React.useState(false);

  // React.useEffect(() => {
  //   if (loggedInUser) {
  //     history.push('/lms/home');
  //   } else {
  //     dispatch(init());
  //   }
  // }, [dispatch, history, loggedInUser]);

  const [form] = Form.useForm();
  const ConfigSettings = useSelector((state) => state.settingsReducer.configs);
  const mailer =
    ConfigSettings?.find((x) => x?.key.toUpperCase() === 'MAILER')?.value ?? 'AMES ENGLISH <mailer@ames.edu.vn>';
  const templateResetPassword =
    ConfigSettings?.find((x) => x?.key === 'TEMPLATE_RESETPASSWORD')?.value ?? 'AMES_Mail_ResetPassword';

  const handleSubmit = (values) => {
    const { username, password } = values;
    if (username === 'it' && password === '849222') {
      localStorage.setItem('it', '849222');
      history.push('/setmodule');
    } else {
      notification.error({ message: 'Sai tài khoản hoặc mật khẩu' });
    }
  };

  const handleForgotPassword = (e) => {
    e.preventDefault();
    setStatusFormForgotPassword(true);
  };
  const handleCancelFormForgotPassword = (e) => {
    e.preventDefault();
    setStatusFormForgotPassword(false);
  };
  const [formForgotPassword] = Form.useForm();

  const sendForgotPassword = async (values) => {
    await query('p_VIMARU_User_SearchByEmail', { Email: values.Email }).then((res) => {
      if (res.length > 0) {
        const { fullName, email, message: messageResult, userId } = res[0];
        if (fullName || email) {
          const GenPassword = GenPasswordModel(2, 6);
          const parameters = {
            Email: email,
            Password: generateSHA1(GenPassword),
          };
          onUpdatePassword(parameters);

          const dateNo = moment().format('YYYYMMDDhhmm');
          const dataSendMail = {
            to: email.toLowerCase(),
            isSendFast: true,
            moduleId: settings.MODULE_ID,
            cc: '',
            bcc: '',
            subject: `Quên mật khẩu #${dateNo}`,
            body: '',
            bodyHTML: '',
            attachment: '',
            templateName: templateResetPassword,
            sourceInfo: `API Save Email - ${templateResetPassword}`,
            templateData: JSON.stringify({
              email: email,
              password: GenPassword,
              dateNo: dateNo,
              studentName: fullName,
            }),
            mailer: mailer,
          };
          amesSendMail('saveEmail', dataSendMail).then((responseSendEmail) => {
            // *If success send email
            if (responseSendEmail.statusText === 'OK') {
              message.success(i18next.t('passSentEmail', { field: email }));
            }
          });
        } else {
          notification.error({ message: messageResult });
          if (messageResult.includes('chưa xác thực')) {
            notification.error({
              message: messageResult,
              description: 'Bạn sẽ chuyển tới trang xác thực trong vòng 3s',
              duration: 0,
            });
            setTimeout(() => {
              window.location.href = `/verify?email=${encodeURIComponent(values.Email)}`;
            }, 3000);
          }
        }
      } else {
        notification.error({ message: 'Email không tồn tại' });
      }
    });
  };
  const onUpdatePassword = async (parameters) => {
    await query('p_VIMARU_UserPassword_RESET', parameters);
  };
  return (
    <React.Fragment>
      <AuthHeader {...props} imgUrl={logoUrl} />
      {!isStatusFormForgotPassword ? (
        <Container className='mt--8 pb-5 transition__form__login'>
          <Row className='justify-content-center'>
            <Col lg='5' md='7'>
              <Card className='bg-secondary border-0 mb-0'>
                <CardHeader className='bg-transparent pb-3'>
                  <div className='text-center mt-2 mb-1'>
                    <span style={{ fontSize: 24, fontWeight: 600, color: colors.font }}>ĐĂNG NHẬP</span>
                  </div>
                  <div className='text-center'>
                    <small>
                      <span>Sử dụng tài khoản </span>
                      <a href='https://englishcity.vn'>Bí mật</a>
                      <span> để đăng nhập</span>
                    </small>
                  </div>
                </CardHeader>
                <CardBody className='px-lg-5 py-lg-5'>
                  <Form autoComplete='on' form={form} onFinish={handleSubmit}>
                    <FormItem
                      hasFeedback
                      name='username'
                      rules={[
                        {
                          whitespace: true,
                          message: 'Vui lòng không xuống dòng',
                        },
                        {
                          required: true,
                          message: 'Vui lòng nhập username (email)!',
                        },
                      ]}
                      maxLength={250}
                    >
                      <Input
                        style={{
                          boxShadow: '0 1px 3px rgba(50, 50, 93, 0.15), 0 1px 0 rgba(0, 0, 0, 0.02)',
                          borderRadius: 3,
                        }}
                        allowClear
                        size='large'
                        prefix={<i style={{ color: 'rgba(0,0,0,.25)' }} className='fas fa-user' />}
                        placeholder='Username (*)'
                      />
                    </FormItem>

                    <FormItem
                      hasFeedback
                      name='password'
                      rules={[
                        // { min: 6, message: "Tối thiểu 6 ký tự!" },
                        {
                          whitespace: true,
                          message: 'Vui lòng không xuống dòng',
                        },
                        { required: true, message: 'Vui lòng nhập mật khẩu!' },
                      ]}
                    >
                      <Input.Password
                        style={{
                          boxShadow: '0 1px 3px rgba(50, 50, 93, 0.15), 0 1px 0 rgba(0, 0, 0, 0.02)',
                          borderRadius: 3,
                        }}
                        allowClear
                        size='large'
                        prefix={<i style={{ color: 'rgba(0,0,0,.25)' }} className='fas fa-lock' />}
                        placeholder='Mật khẩu (*)'
                      />
                    </FormItem>

                    <div className='text-center'>
                      <Button className='mt-2' type='primary' htmlType='submit' size='large' block>
                        Đăng nhập
                      </Button>

                      {/* <Button
                        className='mt-2'
                        type='primary'
                        block
                        danger
                        htmlType='button'
                        size='large'
                        onClick={() => {
                          setModalStudentVisible(true);
                          // window.open('https://hanhtrangso.nxbgd.vn/', '_blank');
                        }}
                      >
                        Đăng ký
                      </Button> */}
                    </div>
                  </Form>
                  {/* <Row className='mt-2'>
                    <Col sm='8'>
                      <div className='text-light' href='#pablo' onClick={(e) => handleForgotPassword(e)}>
                        <span>Quên mật khẩu?</span>
                      </div>
                    </Col>
                  </Row> */}
                  {/* <Row className='mt-2'>
                   <Col sm='5'>
                     <span style={{ cursor: 'pointer' }} className='text-black' onClick={toggleModal}>
                       <small style={{ fontSize: 15 }}>Quên mật khẩu?</small>
                     </span>
                     <ForgotPasswordModal toggleModal={toggleModal} isVisibled={state.isVisibled} />
                   </Col>
                 </Row> */}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      ) : (
        <Container className='mt--8 pb-5 transition__form__login'>
          <Row className='justify-content-center'>
            <Col lg='5' md='7'>
              <Card className='bg-secondary border-0 mb-0'>
                <CardHeader className='bg-transparent pb-3'>
                  <div className='text-center mt-2 mb-1'>
                    <span style={{ fontSize: 24, fontWeight: 600, color: colors.font }}>ĐĂNG NHẬP</span>
                  </div>
                  <div className='text-center'>
                    <small>
                      <span>Sử dụng tài khoản </span>
                      <a href='https://englishcity.vn'>ENGLISH CITY</a>
                      <span> để đăng nhập</span>
                    </small>
                  </div>
                </CardHeader>
                <CardBody className='px-lg-5 py-lg-5'>
                  <Form autoComplete='on' form={formForgotPassword} onFinish={sendForgotPassword}>
                    <FormItem
                      hasFeedback
                      name='Email'
                      rules={[
                        { type: 'email', message: 'Email không hợp lệ' },
                        {
                          whitespace: true,
                          message: 'Vui lòng nhập email!',
                        },
                        {
                          required: true,
                          message: 'Vui lòng nhập email!',
                        },
                      ]}
                      maxLength={250}
                    >
                      <Input
                        style={{
                          boxShadow: '0 1px 3px rgba(50, 50, 93, 0.15), 0 1px 0 rgba(0, 0, 0, 0.02)',
                          borderRadius: 3,
                        }}
                        allowClear
                        size='large'
                        prefix={<i style={{ color: 'rgba(0,0,0,.25)' }} className='fas fa-user' />}
                        placeholder='Username (*)'
                      />
                    </FormItem>

                    <div className='text-center'>
                      <Button className='mt-2' type='primary' htmlType='submit' size='large' block>
                        Gửi
                      </Button>

                      <Button
                        className='mt-2'
                        type='primary'
                        block
                        danger
                        htmlType='button'
                        size='large'
                        onClick={(e) => {
                          handleCancelFormForgotPassword(e);
                          // window.open('https://hanhtrangso.nxbgd.vn/', '_blank');
                        }}
                      >
                        Quay lại đăng nhập
                      </Button>
                    </div>
                  </Form>
                  {/* <Row className='mt-2'>
                <Col sm='5'>
                  <span style={{ cursor: 'pointer' }} className='text-black' onClick={toggleModal}>
                    <small style={{ fontSize: 15 }}>Quên mật khẩu?</small>
                  </span>
                  <ForgotPasswordModal toggleModal={toggleModal} isVisibled={state.isVisibled} />
                </Col>
              </Row> */}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      )}

      <Modal
        isOpen={modalStudentVisible}
        centered
        backdrop='static'
        backdropClassName='sachso-modal-backdrop'
        size='ms'
      >
        <RegisterStudentPopup
          onClose={() => {
            setModalStudentVisible(false);
          }}
        />
      </Modal>
    </React.Fragment>
  );
};

SetModuleLogin.propTypes = {
  history: PropTypes.instanceOf(Object).isRequired,
};
export default SetModuleLogin;
