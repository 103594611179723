import { Button, Modal, Result } from 'antd';
import { ModalProps } from 'pages/AmesOnline/components/type';
import React from 'react';

const ModalError = ({ isVisible, href, onCloseModal }: ModalProps) => {
  return (
    <Modal open={isVisible} closable={false} footer={null} centered={true}>
      <Result
        status='error'
        title='Mã dự thi không hợp lệ hoặc đã được sử dụng'
        // subTitle='Veniam mollit deserunt officia et eu aliquip nostrud esse.'
        extra={[
          <Button
            type='primary'
            onClick={() => {
              //   onCloseModal();
              window.location.replace(href);
            }}
            key='console'
          >
            Về trang chủ
          </Button>,
        ]}
      />
    </Modal>
  );
};

export default ModalError;
