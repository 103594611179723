import Shared_Home from 'pages/Home';
import Profile from 'pages/Profile';
import AccessDenied from 'pages/Errors/AccessDenied';
import { FaCogs, FaTools } from 'react-icons/fa';
import { IoIosNotifications } from 'react-icons/io';
import colors from 'constants/colors';
import React from 'react';

export default [
  {
    // collapse: true,
    component: Shared_Home,
    exact: true,
    icon: 'fas fa-home text-info',
    layout: '/pt',
    name: 'Trang chủ',
    path: '/home',
    roles: ['authenticated'],
    showInSidebar: true,
    sortOrder: 0,
    // state: '/pt/home',
    // views: [],
  },
  {
    path: '/access-denied',
    component: AccessDenied,
    layout: '/pt',
    exact: true,
    roles: ['authenticated'],
  },
  {
    path: '/profile',
    name: 'Profile',
    component: Profile,
    layout: '/pt',
    exact: true,
    roles: ['authenticated'],
  },
  {
    path: '/home/no-data',
    // name: 'Profile',
    component: Shared_Home,
    layout: '/pt',
    exact: true,
    roles: ['authenticated'],
  },
  // {
  //   path: '/notifications',
  //   name: 'Thông báo',
  //   // miniName: 'C',
  //   component: React.lazy(() => import('pages/Vimaru/Notification')),
  //   icon: <IoIosNotifications size={20} className='tw-text-blue-500' />,
  //   layout: '/pt',
  //   exact: true,
  //   roles: ['authenticated'],
  //   showInSidebar: true,
  //   sortOrder: 997,
  // },
  {
    collapse: true,
    name: 'Công cụ',
    icon: <FaTools style={{ color: colors.font }} />,
    roles: ['authenticated'],
    showInSidebar: true,
    sortOrder: 999,
    state: 'pt/tools',
    views: [
      {
        path: '/tools/dictionary',
        name: 'Từ điển',
        miniName: 'D',
        component: React.lazy(() => import('pages/Tools/Dictionary')),
        layout: '/pt',
        exact: true,
        roles: ['authenticated'],
        showInSidebar: true,
        sortOrder: 0,
      },
      {
        path: '/tools/translate',
        name: 'Dịch văn bản',
        miniName: 'D',
        component: React.lazy(() => import('pages/Tools/Translate')),
        layout: '/pt',
        exact: true,
        roles: ['authenticated'],
        showInSidebar: true,
        sortOrder: 1,
      },
    ],
  },
];
