/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import './configs/firebase'; // cái này để lấy thông tin , sử dụng cho GG analist ở phần "Từ điển", "Dịch văn bản"
// react library for routing
import { BrowserRouter, Route, Switch } from 'react-router-dom';
// antd
import { ConfigProvider } from 'antd';
import enUS from 'antd/es/locale/en_US';
import viVN from 'antd/lib/locale/vi_VN';
import moment from 'moment';
import 'moment/locale/vi';
import numeral from 'numeral';
import 'numeral/locales/vi';
// redux
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';

// Component
import NotificationAlert from 'react-notification-alert';
import { Button, Spinner } from 'reactstrap';

import AdminLayout from 'layouts/Admin';
// import RTLLayout from 'layouts/RTL';
import { refNotification } from 'components/RootNotification';

import 'antd/dist/antd.variable.min.css';
import {
  DOMAIN_IS_USE,
  IS_BASAO,
  IS_CDP,
  IS_ISE,
  IS_LG,
  IS_MENARD,
  IS_TRONGDONG,
  IS_TVHH,
  IS_VIMARU,
  PATHNAME,
} from 'constants/settings';
import { initSocket } from 'helpers/SocketHelper';
import i18next from 'i18next';
import Slide from 'layouts/Slide';
import LoginAmesOnline from 'modules/Auth/components/LoginAmesOnline';
import SetModuleLogin from 'modules/Auth/components/SetModuleLogin';
import QuizCityPage from 'pages/QuizCityPage';
import SetModule from 'pages/SetModule';
import ViewReport from 'pages/Teacher/Handout/component/ViewReport';
import Preview from 'pages/Teacher/PTTest/Preview';
import UploadWithQrCode from 'pages/UploadWithQrCode';
import { persistor, store } from './modules/store';
import ConfigSettingsContainer from 'components/Containers/ConfigSettingsContainer';
import NotFound from '404';

import('customCSSByProject/commonCss.css');
// sử dụng file css để custom css cho từng site
if (IS_MENARD) {
  import('customCSSByProject/index_Menard.css');
  import('customCSSByProject/MENARD_custom_antd.less');
} else if (IS_LG) {
  import('customCSSByProject/index_LG.css');
  import('customCSSByProject/LG.less'); // variables to override above
} else if (IS_ISE || IS_TVHH) {
  import('customCSSByProject/index_ISE.css');
  import('customCSSByProject/templateDefault.less');
} else if (IS_BASAO) {
  import('customCSSByProject/index_BASAO.css');
  import('customCSSByProject/BASAO_custom_antd.less');
} else if (IS_TRONGDONG) {
  import('customCSSByProject/index_TRONGDONG.css');
  import('customCSSByProject/TRONGDONG_custom_antd.less');
} else {
  import('customCSSByProject/index_ISE.css');
  import('customCSSByProject/templateDefault.less');
}

initSocket();

// Create a query client
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

// config giờ theo cấu hình ngôn ngữ của i18next
//localeAnt cấu hình kiểu local các component của thư viện antd
let localeAnt = null; // mặc định là tiếng anh
try {
  if (i18next.language === 'en') {
    moment.locale('en');
    numeral.locale('en');
    localeAnt = enUS;
  } else {
    moment.locale('vi');
    numeral.locale('vi');
    localeAnt = viVN;
  }
} catch (err) {
  // console.log(err);
}

const ErrorFallback = ({ error, resetErrorBoundary }) => {
  return (
    <div
      style={{ height: '100vh', backgroundColor: 'white' }}
      className='d-flex flex-column justify-content-center align-items-center'
      role='alert'
    >
      <h2 className='text-lg font-bold text-danger'>{i18next.t('popconfirm.oops')} </h2>
      <pre>{error.message}</pre>
      <Button color='primary' className='mt-4' onClick={() => resetErrorBoundary}>
        {i18next.t('auth:An error occurred, please try again')}
      </Button>
    </div>
  );
};

export default function App() {
  // trong file index.html cũng có < script> cấu hình phần title web
  React.useEffect(() => {
    const faviconLink = document.getElementById('favicon');
    if (IS_CDP) {
      document.title = 'Elearning';
      faviconLink.href = `https://cpd.edu.vn/wp-content/uploads/2023/05/icon-logo-web.png`;
    } else if (IS_LG) {
      document.title = 'TOEIC Learning';
      faviconLink.href = `${DOMAIN_IS_USE}/images/icons/logo_LG.png`;
    } else if (IS_MENARD) {
      document.title = 'VMT School Online';
      faviconLink.href = `${DOMAIN_IS_USE}/images/icons/logo%20menard.png`;
    } else if (IS_VIMARU) {
      document.title = 'Vimaru English City';
      faviconLink.href = 'https://vimaru.edu.vn/sites/default/files/logots_1_0.png';
    } else if (IS_ISE) {
      document.title = 'ISE';
      faviconLink.href = `${DOMAIN_IS_USE}/images/icons/logo-ise.png`;
    } else if (IS_TVHH) {
      document.title = 'TVHH';
      faviconLink.href = `${DOMAIN_IS_USE}/images/icons/logo-vmu-2022.png`;
    } else if (IS_BASAO) {
      document.title = 'TRISTAR';
      faviconLink.href = `${DOMAIN_IS_USE}/images/icons/logo_basao.png`;
    } else if (IS_TRONGDONG) {
      document.title = 'Trống Đồng Palace';
      faviconLink.href = `${DOMAIN_IS_USE}/images/icons/logo_trongdong.png`;
    } else {
      document.title = 'English City';
      faviconLink.href = `${DOMAIN_IS_USE}/images/icons/favicon-english-city.png`;
    }
  }, []);
  return (
    <React.Suspense
      fallback={
        <div className='h-100 w-100 d-flex justify-content-center align-items-center'>
          <Spinner />
        </div>
      }
    >
      <DndProvider backend={HTML5Backend}>
        <QueryClientProvider client={queryClient}>
          <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
              <ConfigProvider
                locale={localeAnt}
                // csp={{ nonce: 'YourNonceCode' }}
                // getPopupContainer={(node) => {
                //   if (node) {
                //     return node.parentElement;
                //   }
                //   return document.body;
                // }}
              >
                <ConfigSettingsContainer>
                  <div className='rna-wrapper' style={{ zIndex: 999999 }}>
                    <NotificationAlert ref={refNotification} />
                  </div>
                  {/* <ErrorBoundary
                  FallbackComponent={ErrorFallback}
                  onError={(error, info) => {
                    console.log('🚀 error', error);
                    console.log('🚀 info', info);
                  }}
                > */}
                  <BrowserRouter>
                    <Switch>
                      <Route
                        path={`/${PATHNAME}/student/codeExam/:codeExam/preview`}
                        render={(props) => <Preview {...props} />}
                      />
                      <Route
                        path={`/${PATHNAME}/student/presentations/:presentationId/slides/:slideId`}
                        render={(props) => <Slide {...props} />}
                      />
                      <Route path={`/handout/teacher/:handoutCode`} render={(props) => <ViewReport {...props} />} />
                      <Route path='/handout/student/:codeExam' render={(props) => <LoginAmesOnline {...props} />} />
                      <Route path='/pt/student/:codeExam' render={(props) => <LoginAmesOnline {...props} />} />
                      <Route path={`/${PATHNAME}`} render={(props) => <AdminLayout {...props} />} />

                      <Route path='/auth' render={(props) => <QuizCityPage {...props} />} />
                      <Route
                        path='/upload/qrcode/:moduleName/:subModuleName/:entityName/:entityId'
                        exact
                        render={(props) => <UploadWithQrCode {...props} />}
                      />
                      <Route exact path='/login/setmodule' render={(props) => <SetModuleLogin {...props} />} />
                      <Route exact path='/setmodule' render={(props) => <SetModule {...props} />} />
                      {/* Để rẽ nhánh subdomain vào đăng nhập */}
                      {/* <Route path='/:subdomain' render={(props) => <QuizCityPage {...props} />} /> */}
                      <Route path={[`/auth/${PATHNAME}`, '/verify']} render={(props) => <QuizCityPage {...props} />} />
                      <Route exact path='/' render={(props) => <QuizCityPage {...props} />} />
                      <Route path='*' render={(props) => <NotFound {...props} />} />
                    </Switch>
                  </BrowserRouter>
                  {/* </ErrorBoundary> */}
                </ConfigSettingsContainer>
              </ConfigProvider>
            </PersistGate>
          </Provider>
          <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>
      </DndProvider>
    </React.Suspense>
  );
}
