import { CloseOutlined, LeftOutlined, RightOutlined } from '@ant-design/icons';
import { Button, Card, Col, Modal, Progress, Row, Spin } from 'antd';
import SimpleContainer from 'components/Containers/SimpleContainer';
import numeral from 'numeral';
import React, { useEffect, useRef, useState } from 'react';
import { AiFillCheckCircle, AiFillLock, AiFillPlayCircle, AiOutlineClose, AiOutlineLock } from 'react-icons/ai';
// import { Button } from 'reactstrap';
import Presentation from './Presentation/LGPresentation';
import styles from './swimlanelg.module.css';
import colors from 'constants/colors';
import { query } from 'helpers/QueryHelper';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { TfiHeadphoneAlt } from 'react-icons/tfi';
import { RiComputerLine } from 'react-icons/ri';
import { BsFillPersonCheckFill } from 'react-icons/bs';
import { GiStairsGoal } from 'react-icons/gi';
import { CircularProgressbarWithChildren } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

type Props = {
  folders: any;
  setCoachingGuideModalVisible?: Function;
};
const theme = [
  '#95DABB',
  '#1DD5EE',
  '#42F7C0',
  '#FE9666',
  '#F64F5C',
  '#01B8AA',
  '#374649',
  '#FD625E',
  '#F2C80F',
  '#5F6B6D',
  '#8AD4EB',
  '#A66999',
  '#73B761',
  '#4A588A',
  '#CD4C46',
];

const lessonColors = ['#6EC7D7', '#3DB4C9', '#0DA1BC', '#0A798D'];
const routes = ['Common', 'Elementary', 'Intermediate', 'Coaching'];
const icons = [
  <TfiHeadphoneAlt size={40} />,
  <RiComputerLine size={40} />,
  <BsFillPersonCheckFill size={40} />,
  <GiStairsGoal size={40} />,
];
const circleImages = [
  require('assets/img/icons/lg/Introduction.png').default,
  require('assets/img/icons/lg/Learn & Practice.png').default,
  require('assets/img/icons/lg/Sample & Test.png').default,
  require('assets/img/icons/lg/Coaching.png').default,
];
const progressBarColor = (backgroundColor: string) => {
  switch (backgroundColor.toUpperCase()) {
    case '#03A9F4':
      return '#0285C2';
    case '#000000':
      return '#999999';
    case '#F44336':
      return '#C2352B';
    case '#E91E63':
      return '#B5184C';
    case '#9C27B0':
      return '#6E1B7D';
    case '#673AB7':
      return '#4B2A85';
    case '#3F51B5':
      return '#2E3A82';
    case '#2196F3':
      return '#1B75BF';
    case '#00BCD4':
      return '#008EA1';
    case '#009688':
      return '#00635A';
    case '#4CAF50':
      return '#367D38';
    case '#8BC34A':
      return '#658F36';
    case '#CDDC39':
      return '#9CA82C';
    case '#FFEB3B':
      return '#CCBC2F';
    case '#FFC107':
      return '#CC9B06';
    case '#FF9800':
      return '#CC7A00';
    case '#FF5722':
      return '#CC441B';
    case '#795548':
      return '#453129';
    case '#607D8B':
      return '#3E5059';
    case '#00BCD4':
      return '#008EA1';
    default:
      return 'gray';
  }
};

const circularProgressBarStyle = {
  root: {
    width: '10vw',
    height: '10vw',
    maxWidth: 150,
    maxHeight: 150,
    minWidth: 120,
    minHeight: 120,
    cursor: 'pointer',
  },
  path: {
    stroke: colors.theme.button,
  },
};
function lightenColor(color, amount = 0.3) {
  // convert the hex color to RGB
  const rgb = hexToRgb(color);
  // calculate the new RGB values
  const newRgb = {
    r: Math.min(Math.round(rgb.r + (255 - rgb.r) * amount), 255),
    g: Math.min(Math.round(rgb.g + (255 - rgb.g) * amount), 255),
    b: Math.min(Math.round(rgb.b + (255 - rgb.b) * amount), 255),
  };
  // convert the new RGB values back to hex
  return rgbToHex(newRgb.r, newRgb.g, newRgb.b);
}

function hexToRgb(hex) {
  const r = parseInt(hex.substr(1, 2), 16);
  const g = parseInt(hex.substr(3, 2), 16);
  const b = parseInt(hex.substr(5, 2), 16);
  return { r, g, b };
}

function rgbToHex(r, g, b) {
  return `#${componentToHex(r)}${componentToHex(g)}${componentToHex(b)}`;
}

function componentToHex(c) {
  const hex = c.toString(16);
  return hex.length === 1 ? '0' + hex : hex;
}
const fetchFolder = (folderId, studentId) => () => {
  if (!folderId) return;

  const parameters = {
    studentId: studentId,
    folderId: folderId,
  };
  return query('p_QUIZCITY_Get_Folders_Presentation_ByLesson', parameters);
};
export default function SwimlaneLG({ folders, setCoachingGuideModalVisible }: Props) {
  const storedSelectedFolderIndex = localStorage.getItem('folderIndexSwimlane');
  const storedSelectedLessonIndex = localStorage.getItem('lessonIndexSwimlane');
  const [selectedFolder, setSelectedFolder] = useState<any>(
    storedSelectedFolderIndex ? folders[storedSelectedFolderIndex] : null,
  );

  const [widthScreen, setWidthScreen] = useState<number>(window.outerWidth);
  const [selectedLessonIndex, setSelectedLessonIndex] = useState<number>(parseInt(storedSelectedLessonIndex) ?? null);
  const [visible, setVisible] = useState<boolean>(false);
  const loggedInUser = useSelector((state) => state.authReducer.loggedInUser);
  const folderId = selectedFolder?.lessons?.[selectedLessonIndex]?.id ?? selectedFolder?.id;

  const KEY_FETCH_FOLDER = ['p_QUIZCITY_Get_Folders_Presentation_ByLesson', folderId, loggedInUser?.id];
  const { data, isLoading } = useQuery(KEY_FETCH_FOLDER, fetchFolder(folderId, loggedInUser?.id),{enabled: !!folderId});
  const rowRefs = useRef<Array<HTMLDivElement | null>>([]);

  useEffect(() => {
    const handleResize = (e) => {
      // Xử lý sự kiện thay đổi kích thước màn hình ở đây
      // console.log('Kích thước màn hình đã thay đổi', e.target.outerWidth);
      setWidthScreen(e.target.outerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []); // Dependency array rỗng để đảm bảo hiệu chỉnh chỉ xảy ra khi component được gắn kết

  const isFolderDone = (folder) => {
    const check = folder.lessons.every((lesson) => {
      return lesson.presentations.every((presentation) => {
        return presentation.slides.every((slide) => {
          return slide.status === 'Completed';
        });
      });
    });

    return check;
  };
  const renderCircle = (text, icon, index, onClick, percent) => {
    return (
      <div style={{ width: index < 3 ? '100%' : '25%' }}>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div style={{ cursor: 'pointer' }} onClick={onClick}>
            <CircularProgressbarWithChildren strokeWidth={5} styles={circularProgressBarStyle} value={percent}>
              <img className='lg-icon-transition' src={icon} />
            </CircularProgressbarWithChildren>
          </div>
          {index !== 3 && (
            <div style={{ display: 'flex', alignItems: 'center', marginInline: 11 }}>
              <div style={{ width: '7.4vw', height: 5, backgroundColor: 'grey' }}></div>
              <div className={styles.triangleRight}></div>
            </div>
          )}
          <div></div>
        </div>
        <div
          style={{
            width: '10vw',
            textAlign: 'center',
            fontWeight: 'bold',
            color: 'black',
            fontSize: 17,
            maxWidth: 150,
            minWidth: 120,
          }}
        >
          {text}
        </div>
      </div>
    );
  };
  const renderLessons = () => {
    const lessonArray = [];
    selectedFolder.lessons.map((lesson, index: number) => {
      const { color, id: lessonId } = lesson;
      lessonArray.push(
        <Row
          key={lessonId}
          className='swimlane_col_lesson_container'
          style={{ backgroundColor: `${index % 2 === 1 ? '' : '#f2f1ed '}`, padding: '0px ' }}
        >
          <div className={styles.col_lesson_content} style={{ backgroundColor: color ?? lessonColors[index] }}>
            {lesson.title.toUpperCase()}
          </div>
        </Row>,
      );
    });
    return <Col>{lessonArray}</Col>;
  };
  const handleFolderTitle = (title: string) => {
    const splittedTitle = title.split('(');
    const folderTitle = splittedTitle[0];
    const deadline = splittedTitle[1];
    return (
      <div>
        <div>{folderTitle}</div>
        <div>{deadline && `(${deadline}`}</div>
      </div>
    );
  };

  const renderBody = () => {
    return (
      data?.[0]?.lessons
        // ?.sort((a, b) => a.sortOrder - b.sortOrder)
        ?.sort((a, b) => a.sortOrder - b.sortOrder)
        ?.map((lesson, index: number) => {
          return (
            <div style={{ position: 'relative' }}>
              <Row
                className={styles.col_folder_lesson}
                wrap={widthScreen < 576}
                style={{
                  gap: 3,
                  backgroundColor: `${index % 2 === 1 ? '' : '#f2f1ed '}`,
                  // overflow: 'hidden',
                }}
                ref={(ref) => (rowRefs.current[index] = ref)}
              >
                <Col
                  className={styles.col_lesson_content}
                  style={{
                    backgroundColor: lesson.color ?? lessonColors[index],
                    // height: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    textAlign: 'center',
                    alignItems: 'center',
                    width: '9vw',
                    borderRadius: 10,
                    fontWeight: 600,
                  }}
                >
                  {lesson.title}
                </Col>

                {lesson.presentations
                  ?.sort((a, b) => a.sortOrder - b.sortOrder)
                  ?.map((presentation) => {
                    const { id: presentationId } = presentation;
                    return (
                      <Col
                        key={presentationId}
                        style={{
                          width: widthScreen > 576 ? '14.4vw' : '100%',
                        }}
                      >
                        <Presentation presentation={presentation} color={lesson?.color ?? lessonColors[index]} />
                      </Col>
                    );
                  })}
              </Row>
            </div>
            // </Col>
          );
        })
    );
  };
  const handleWeekClick = (folder) => {
    setVisible(true);
    setSelectedFolder(folder);
  };

  const renderWeeks = () => {
    const weeks = folders.map((folder, folderIndex) => {
      const folderColor = folder?.color ?? '#81dff0';
      const handleClick = () => {
        handleWeekClick(folder);
        localStorage.setItem('folderIndexSwimlane', folderIndex);
      };
      return (
        // <Row
        //   className={styles.row_week}
        //   onClick={() => {
        //     handleWeekClick(folder);
        //     localStorage.setItem('folderIndexSwimlane', folderIndex);
        //   }}
        //   wrap={false}
        // >
        //   {widthScreen > 576 ? (
        //     <>
        //       <Col>{renderWeekCard(folder.title, folderColor)}</Col>
        //       <Col style={{ display: 'flex' }}>{renderArrowLessons(folder?.lessons)}</Col>
        //     </>
        //   ) : (
        //     <Col span={24}>{renderWeekCard(folder.title, folderColor)}</Col>
        //   )}

        //   {/* <Col
        //     className={'p-2'}
        //     span={1}
        //     style={{
        //       // borderBottom: folderIndex !== folders.length - 1 ? '1px solid grey' : '',
        //       justifyContent: 'center',
        //       alignItems: 'center',
        //       display: 'flex',
        //     }}
        //   >
        //     {isFolderDone(folder) && <AiFillCheckCircle color={'#01B8AA'} size={40} className='tw-ml-2' />}
        //   </Col> */}
        // </Row>

        renderCircle(folder?.title, circleImages[folderIndex], folderIndex, handleClick, folder.percentCompleted * 100)
      );
    });
    weeks.push(
      renderCircle(
        routes[3],
        circleImages[3],
        3,
        () => {
          setCoachingGuideModalVisible?.(true);
        },
        0,
      ),
    );
    return weeks;
  };

  const renderArrowLessons = (lessons: Array<any>) => {
    const arrowLessons = lessons?.map((lesson, index) => {
      const lessonColor = lesson?.color ?? lessonColors[index];
      return (
        <div
          onClick={() => {
            setSelectedLessonIndex(index);
            localStorage.setItem('lessonIndexSwimlane', index);
          }}
          style={{ position: 'relative' }}
        >
          <div
            className={styles.arrow_tag}
            style={{
              height: 63,
              width: widthScreen > 576 ? 350 : '100%',
              zIndex: 100 - index * 2,
              backgroundImage: `linear-gradient(to right, ${lessonColor}, ${lightenColor(lessonColor, 0.18)})`,
            }}
          >
            <div style={{ display: 'flex', padding: '5px 10px 0 30px' }}>
              <div style={{ flex: 5, whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden' }}>
                {lesson.title}
              </div>
              <div style={{ flex: 1 }}>
                {' '}
                {lesson.status === 'Completed' && (
                  <AiFillCheckCircle
                    style={{ backgroundColor: colors.green, borderRadius: '50%' }}
                    color='white'
                    size={20}
                    className='tw-ml-2'
                  />
                )}
                {lesson.status === 'Locked' && (
                  <AiFillLock
                    style={{
                      backgroundColor: 'white',
                      borderRadius: '50%',
                      border: '1px solid',
                      borderColor: colors.dimGray,
                      padding: 1,
                    }}
                    color={colors.dimGray}
                    size={20}
                    className='tw-ml-2'
                  />
                )}
                {lesson.status === 'Playing' && (
                  <AiFillPlayCircle
                    style={{ backgroundColor: colors.gold, borderRadius: '50%' }}
                    color='white'
                    size={20}
                    className='tw-ml-2'
                  />
                )}
              </div>
            </div>
            <div style={{ display: 'flex', paddingInline: '30px 10px', fontSize: 16 }}>
              <div style={{ flex: 5 }}>
                <Progress
                  trailColor={'white'}
                  strokeColor={progressBarColor(lesson?.color ?? lessonColors[index])}
                  percent={lesson.percentCompleted * 100}
                  showInfo={false}
                />
              </div>
              <div style={{ textAlign: 'center', flex: 1 }}>
                <span className='tw-ml-2'>{numeral(lesson.percentCompleted).format('%')}</span>
              </div>
            </div>
          </div>
          <div className={styles.arrow_part} style={{ zIndex: 99 - index * 2 }}></div>
        </div>
      );
    });
    return arrowLessons;
  };

  return (
    <>
      <div
        style={{
          overflowX: 'auto',
          display: 'flex',
          justifyContent: 'space-between',
          width: '100%',
        }}
      >
        {renderWeeks()}
      </div>
      <Modal
        open={visible}
        onCancel={() => {
          setVisible(false);
          localStorage.removeItem('lessonIndexSwimlane'); // Nhấn ra ngoài thì xóa storage.
          localStorage.removeItem('folderIndexSwimlane'); // Nhấn ra ngoài thì xóa storage.
        }}
        width='100vw'
        footer={null}
        closable={false}
        centered
        bodyStyle={{ padding: 0 }}
      >
        <Card
          title={<div style={{ color: colors.theme.button }}>{data?.[0]?.title}</div>}
          style={{ padding: 0, minHeight: '90vh' }}
          headStyle={{ fontSize: 21 }}
          bodyStyle={{ overflow: 'scroll', height: '90vh' }}
          extra={
            <Button
              type='primary'
              icon={<CloseOutlined />}
              danger
              onClick={() => {
                setVisible(false);
                localStorage.removeItem('folderIndexSwimlane'); // Nhấn nút đóng thì xóa storage.
                localStorage.removeItem('lessonIndexSwimlane'); // Nhấn nút đóng thì xóa storage.
              }}
            />
          }
        >
          {isLoading ? (
            <div
              style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}
            >
              <Spin size='large' spinning={isLoading} />
            </div>
          ) : (
            <Col>{renderBody()}</Col>
          )}
        </Card>
      </Modal>
    </>
  );
}
