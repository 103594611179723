import { Progress } from 'antd';
import settings, { IS_BASAO, IS_LX, IS_TRONGDONG } from 'constants/settings';
import i18next from 'i18next';
import SlideDetailPreview from './SlideDetailPreview';

export default {
  rowKey: 'id',
  displayColumns: [
    {
      key: 'createdDate',
      render: 'renderDateTime',
      title: i18next.t('Date'),
      width: '10%',
    },
    {
      key: 'lessonTitle',
      // render: 'renderNoWrap',
      style: {
        fontWeight: 600,
        minWidth: 72,
      },
      title: 'Lesson',
      width: '10%',
    },

    {
      key: 'presentationTitle',
      // render: 'renderNoWrap',
      style: {
        fontWeight: 600,
        minWidth: 72,
      },
      title: 'Part',
      width: '10%',
    },
    {
      key: 'title',
      // render: 'renderNoWrap',
      style: {
        fontWeight: 600,
        minWidth: 72,
      },
      title: i18next.t('Name'),
      width: '10%',
    },
    {
      key: 'total',
      render: 'renderText',
      style: {
        fontWeight: 600,
        minWidth: 72,
      },
      title: i18next.t('table:classInformation.Completed quantity'),
      width: '10%',
      dataType: 'math',
    },
    {
      key: 'qualityPercent',
      dataType: 'number',
      render: (qualityPercent, record, index) => {
        return <Progress percent={`${Number.parseFloat(qualityPercent ?? 0).toFixed(0)}`} status='active' />;
      },
      style: {
        fontWeight: 600,
        minWidth: 72,
      },
      title: i18next.t('table:classInformation.Completed quality'),
      width: '10%',
    },
  ].concat(
    //Hiển thị với site HighUp và Menard
    settings.IS_HIGHUP || settings.IS_MENARD || IS_BASAO || IS_TRONGDONG || IS_LX
      ? [
          {
            key: 'details',
            render: (text, record) => {
              return <SlideDetailPreview record={record} />;
            },
            sort: false,
            style: {
              fontWeight: 400,
            },
            title: '',
            width: '1%',
          },
        ]
      : [],
  ),
};
