import { Button, Modal } from 'antd';
import { query } from 'helpers/QueryHelper';
import ExcercisePage from 'modules/WorkSheet/CanvasToolPlus/ExcercisePage';
import React from 'react';
import { useQuery } from 'react-query';
import QuizDetailPreview from './QuizDetailPreview';

const fetcher = (logId, slideId, studentId, type) => {
  return query('p_VIMARU_GetAnswerLogs_ByStudentId_Details_Lev2', { logId, slideId, studentId, type });
};
export default function SlideDetailPreview({ record }) {
  const [visible, setVisible] = React.useState(false);

  const KEY = [
    'p_VIMARU_GetAnswerLogs_ByStudentId_Details_Lev2',
    record?.slideId,
    record?.studentId,
    record?.id,
    record?.type,
  ];

  const { data, isLoading } = useQuery(
    KEY,
    () => fetcher(record?.id, record?.slideId, record?.studentId, record?.type),
    { enabled: visible },
  );
  return (
    <>
      <Button type='text' style={{ color: '#006EE6' }} onClick={() => setVisible(true)}>
        Xem trước
      </Button>
      <Modal
        destroyOnClose
        centered
        width='90vw'
        bodyStyle={{ height: '80vh', overflow: 'auto' }}
        open={visible}
        title='Chi tiết lần làm'
        onCancel={() => setVisible(false)}
        footer={[
          <Button
            type='primary'
            key='close-modal'
            onClick={() => {
              setVisible(false);
            }}
          >
            Đóng
          </Button>,
        ]}
      >
        {record?.type === 'WorkSheet' && (
          <ExcercisePage
            typeView={'view'}
            userAnswer={data?.[0]?.jsonData}
            loadingAnswer={false}
            isHiddenShowColor={true}
          />
        )}
        {record?.type === 'Quiz' && <QuizDetailPreview data={data} />}
      </Modal>
    </>
  );
}
