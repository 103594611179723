/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/media-has-caption */
import { ReactMic } from '@cleandersonlobo/react-mic';
import { useDebounceFn } from 'ahooks';
import { Avatar, Badge, Button, Card, Divider, Form, Input, notification, Popover, Progress, Space } from 'antd';
import axios from 'axios';
import classnames from 'classnames';
import StudentContainer from 'components/Containers/StudentContainer';
import TeacherContainer from 'components/Containers/TeacherContainer';
import colors from 'constants/colors';
import withAuthenticator from 'HOCs/withAuthenticator';
import _ from 'lodash';
import numeral from 'numeral';
import React, { Dispatch, SetStateAction } from 'react';
import { Spinner } from 'reactstrap';
import { LoggedInUserType } from 'types';
import { ResultProps, SpeechRecognitionResultProps } from '../types';
import DynamicButton from 'packages/DynamicButton/DynamicButton';

interface Props {
  text: string;
  selectedIndex: number;
  testSpeech: any;
  loggedInUser?: LoggedInUserType;
  resultDataAnswer: ResultProps;
  setSpeechRecognitionResult?: Dispatch<SetStateAction<SpeechRecognitionResultProps>>;
  isDone: boolean; // Làm đi làm lại nhiều lần.
  isCompleted: boolean; // Nộp bài lần cuối để giáo viên chấm.
}

const SpeechRecognition = ({
  text,
  selectedIndex,
  testSpeech,
  loggedInUser,
  setSpeechRecognitionResult,
  resultDataAnswer,
  isDone,
  isCompleted,
}: Props) => {
  const [loading, setLoading] = React.useState(false);

  const [recordEnable, setRecordEnable] = React.useState(true);
  const [isRecording, setIsRecording] = React.useState(false);
  const [base64String, setBase64String] = React.useState(null);
  const [recognitionResult, setRecognitionResult] = React.useState(null);

  const [form] = Form.useForm();

  const errorTypeNumbers: any = _.countBy(recognitionResult?.pronunciationAssessment?.words, 'errorType');
  const requestRecorder = async () => {
    try {
      await navigator.mediaDevices.getUserMedia({ audio: true });
    } catch (error) {
      notification.error({ message: 'Trình duyệt của bạn chưa hỗ trợ thu âm. Vui lòng thiết lập cài đặt.' });
      setRecordEnable(false);
    }
  };

  React.useEffect(() => {
    if (!isDone) {
      // Nếu học sinh đã nộp bài, thì khi giáo viên xem bài, không hiển thị cảnh báo.
      requestRecorder();
    } else if (isDone && resultDataAnswer?.userData) {
      // Hiển thị kết quả bài làm của học sinh khi giáo viên xem bài.
      setRecognitionResult(resultDataAnswer?.userData?.[selectedIndex]?.userAnswer);
    }
  }, [isDone, resultDataAnswer, selectedIndex]);

  const RecordIcon = () => {
    if (!recordEnable) {
      return (
        <div>
          <i style={{ fontSize: 24 }} className='fas fa-microphone-slash' />
        </div>
      );
    }

    if (isRecording) {
      return (
        <div>
          <i style={{ fontSize: 24 }} className='fas fa-stop ' />
        </div>
      );
    }
    return (
      <div>
        <i style={{ fontSize: 24 }} className='fas fa-microphone' />
      </div>
    );
  };

  const recognize = async (recordData: string | ArrayBuffer, readingText: string) => {
    // POST DATA p_SACHSO_CLASSWORK_GetAnswerByAttachmentId SERVER
    setLoading(true);
    const formData = new FormData();
    formData.append('base64String', _.replace(recordData.toString(), 'data:audio/wav;base64,', ''));
    formData.append('readingText', readingText);
    formData.append('studentId', loggedInUser?.id.toString());

    const response = await axios.post('https://server.sachso.edu.vn/api/v1.0/SpeechRecognition', formData, {
      headers: { 'Content-Type': 'multipart/form-data', Authorization: 'Basic 12C1F7EF9AC8E288FBC2177B7F54D' },
    });
    setRecognitionResult(response.data.result);
    setLoading(false);
    setSpeechRecognitionResult({ ...response.data.result, base64String });
  };

  const { run: sendDataToServer } = useDebounceFn(
    () => {
      form.submit();
    },
    { wait: 1000 },
  );

  const onStop = React.useCallback(
    (recordedBlob: any) => {
      const reader = new FileReader();
      reader.readAsDataURL(recordedBlob.blob);
      reader.onloadend = async () => {
        setBase64String(reader.result);
        sendDataToServer();
      };
    },
    [sendDataToServer],
  );

  return (
    <div>
      <div className='tw-mb-8'>
        <h3>Sample sentence</h3>
        <Form
          initialValues={{ sampleSentence: text }}
          form={form}
          name='recognize-form'
          onFinish={(values: any) => {
            recognize(base64String, values.sampleSentence);
          }}
        >
          <Form.Item name='sampleSentence'>
            <Input.TextArea style={{ fontSize: 24, fontWeight: 500 }} rows={3} />
          </Form.Item>
        </Form>
      </div>

      <div className='react-record-control' style={{ height: 1 }}>
        <ReactMic visualSetting='none' record={isRecording} onStop={onStop} mimeType='audio/wav' />
      </div>

      <div className='tw-flex tw-justify-center tw-items-center tw-flex-col'>
        {base64String && (
          <div>
            <audio src={base64String} controls />
          </div>
        )}

        <Space className='tw-mt-8'>
          {resultDataAnswer?.userData[selectedIndex]?.userAnswer ? (
            <div>
              <audio src={resultDataAnswer?.userData[selectedIndex]?.userAnswer?.base64String} controls />
            </div>
          ) : (
            <>
              <TeacherContainer>
                {/* Nếu học sinh đã nộp bài, thì không hiển thị nút thu âm. */}
                {!isDone ? (
                  <DynamicButton
                    className={classnames({ 'button-recording-pulse': isRecording })}
                    style={{ height: 100, width: 100 }}
                    danger={recordEnable && isRecording}
                    disabled={!recordEnable || loading}
                    shape='circle'
                    icon={loading ? <Spinner /> : <RecordIcon />}
                    onClick={() => {
                      // Reset before get new result
                      if (!isRecording) {
                        setRecognitionResult(null);
                      }

                      setIsRecording(!isRecording);
                    }}
                    type='primary'
                  >
                    {loading ? null : isRecording ? 'Dừng' : 'Thu âm'}
                  </DynamicButton>
                ) : null}
              </TeacherContainer>
              <StudentContainer>
                {!isCompleted ? (
                  <DynamicButton
                    className={classnames({ 'button-recording-pulse': isRecording })}
                    style={{ height: 100, width: 100 }}
                    danger={recordEnable && isRecording}
                    disabled={!recordEnable || loading}
                    shape='circle'
                    icon={loading ? <Spinner /> : <RecordIcon />}
                    onClick={() => {
                      // Reset before get new result
                      if (!isRecording) {
                        setRecognitionResult(null);
                      }

                      setIsRecording(!isRecording);
                    }}
                    type='primary'
                  >
                    {loading ? null : isRecording ? 'Dừng' : 'Thu âm'}
                  </DynamicButton>
                ) : null}
              </StudentContainer>
            </>
          )}
        </Space>
      </div>

      <Card className='tw-mt-8' title='KẾT QUẢ'>
        <div style={{ fontWeight: 500, fontSize: 24 }}>
          {recognitionResult?.pronunciationAssessment?.words?.map(
            (word: {
              word: string;
              accuracyScore: number;
              errorType: 'None' | 'Omission' | 'Insertion' | 'Mispronunciation';
              phonemes: [];
            }) => {
              const statusColor: React.CSSProperties = {
                cursor: 'pointer',
                color: colors.font,
                backgroundColor: 'transparent',
                textDecorationLine: 'underline',
                textDecorationStyle: 'dashed',
                textDecorationThickness: 1,
                textUnderlineOffset: 2,
              };

              if (word.errorType === 'Insertion') {
                statusColor.color = colors.theme.warning;
                // statusColor.color = 'white';
              } else if (word.errorType === 'Omission') {
                statusColor.color = colors.theme.danger;
                // statusColor.color = 'white';
              } else if (word.errorType === 'Mispronunciation') {
                statusColor.color = colors.theme.info;
                // statusColor.color = 'white';
              }

              return (
                <React.Fragment key={`word-${word.word}`}>
                  <Popover
                    // visible={word.errorType === 'None'}
                    title={`${word.word}: ${numeral(word.accuracyScore / 100).format('0%')}`}
                    content={() => {
                      return (
                        <Space className='tw-flex'>
                          {word.phonemes.map((p: { phoneme: string; accuracyScore: number }) => {
                            return (
                              <div key={p.phoneme} className='tw-flex tw-flex-col tw-items-center'>
                                <div style={{ fontSize: 16 }}>{p.phoneme}</div>
                                <div style={{ fontSize: 12 }}>{p.accuracyScore}</div>
                              </div>
                            );
                          })}
                        </Space>
                      );
                    }}
                  >
                    <span style={statusColor}>{`${word.word}`}</span>
                  </Popover>
                  <span> </span>
                </React.Fragment>
              );
            },
          )}
        </div>

        <Divider />

        <div className='tw-flex tw-justify-center'>
          <Space size='large'>
            <Space direction='vertical' className='tw-justify-center tw-items-center'>
              <Progress
                type='circle'
                status='active'
                percent={
                  recognitionResult?.pronunciationAssessment?.pronunciationScore || // A.I. chấm điểm
                  testSpeech[selectedIndex]?.userAnswer?.pronunciationAssessment?.pronunciationScore || // chưa nộp bài
                  resultDataAnswer?.userData[selectedIndex]?.userAnswer?.pronunciationAssessment?.pronunciationScore // hiển thị sau nộp bài
                }
                width={100}
                strokeColor='#52c41a'
              />
              <strong className='tw-font-medium'>Phát âm</strong>
            </Space>

            <Space direction='vertical' className='tw-justify-center tw-items-center'>
              <Progress
                type='circle'
                status='active'
                percent={
                  recognitionResult?.pronunciationAssessment?.accuracyScore ||
                  testSpeech[selectedIndex]?.userAnswer?.pronunciationAssessment?.accuracyScore ||
                  resultDataAnswer?.userData[selectedIndex]?.userAnswer?.pronunciationAssessment?.accuracyScore
                }
                width={100}
                strokeColor='#722ed1'
              />
              <strong className='tw-font-medium'>Chính xác</strong>
            </Space>
            <Space direction='vertical' className='tw-justify-center tw-items-center'>
              <Progress
                type='circle'
                status='active'
                percent={
                  recognitionResult?.pronunciationAssessment?.completenessScore ||
                  testSpeech[selectedIndex]?.userAnswer?.pronunciationAssessment?.completenessScore ||
                  resultDataAnswer?.userData[selectedIndex]?.userAnswer?.pronunciationAssessment?.completenessScore
                }
                width={100}
                strokeColor='#2f54eb'
              />
              <strong className='tw-font-medium'>Hoàn thiện</strong>
            </Space>
            <Space direction='vertical' className='tw-justify-center tw-items-center'>
              <Progress
                type='circle'
                status='active'
                percent={
                  recognitionResult?.pronunciationAssessment?.fluencyScore ||
                  testSpeech[selectedIndex]?.userAnswer?.pronunciationAssessment?.fluencyScore ||
                  resultDataAnswer?.userData[selectedIndex]?.userAnswer?.pronunciationAssessment?.fluencyScore
                }
                width={100}
                strokeColor='#eb2f96'
              />
              <strong className='tw-font-medium'>Lưu loát</strong>
            </Space>
          </Space>
        </div>

        <Divider />

        <section id='recognition-legend' className='tw-flex tw-justify-center'>
          <Space size='large' className='tw-mt-2'>
            <Space>
              <Badge showZero color={colors.font} count={errorTypeNumbers?.Omission ?? 0}>
                <Avatar size='default' shape='circle' style={{ backgroundColor: colors.theme.danger }} />
              </Badge>
              <strong>Đọc thiếu từ</strong>
            </Space>
            <Space>
              <Badge showZero color={colors.font} count={errorTypeNumbers?.Mispronunciation ?? 0}>
                <Avatar size='default' shape='circle' style={{ backgroundColor: colors.theme.info }} />
              </Badge>

              <strong>Phát âm sai</strong>
            </Space>

            <Space>
              <Badge showZero color={colors.font} count={errorTypeNumbers?.Insertion ?? 0}>
                <Avatar size='default' shape='circle' style={{ backgroundColor: colors.theme.warning }} />
              </Badge>
              <strong>Đọc thừa từ</strong>
            </Space>
          </Space>
        </section>
      </Card>
    </div>
  );
};

SpeechRecognition.defaultProps = {
  loggedInUser: undefined,
  setSpeechRecognitionResult: null,
};

export default withAuthenticator(SpeechRecognition);
