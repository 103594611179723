/* eslint-disable react/jsx-props-no-spreading */
import { Button, Form, Input } from 'antd';
// core components
import colors from 'constants/colors';
import IndexPresentationAmesOnline from 'pages/AmesOnline';
import React from 'react';
import { useParams } from 'react-router';

import settings from 'constants/settings';
import { query } from 'helpers/QueryHelper';
import { start } from 'modules/Presentation/Viewer/actions';
import { AiOutlineBranches } from 'react-icons/ai';
import { BiCodeAlt } from 'react-icons/bi';
import { BsFillBuildingsFill } from 'react-icons/bs';
import { SiGoogleclassroom } from 'react-icons/si';
import { useDispatch } from 'react-redux';
import { Card, CardBody, CardHeader, Col, Row } from 'reactstrap';
import ModalError from './Modal/ModalError';

type TypeParams = {
  codeExam: string;
};

const logoUrl =
  window.location.hostname === 'quizcity.io'
    ? require('assets/img/brand/logo-quizcity.png').default
    : require('assets/img/brand/english-city-01.png').default;
const bannerUrl = require('assets/img/introduce/ames-login-BG.png').default;

const LoginAmesOnline = (props: any) => {
  const [isViewPresentation, setIsViewPresentation] = React.useState<boolean>(false);
  const [isShowModalError, setIsShowModalError] = React.useState<boolean>(false);
  // const [response, setResponse] = React.useState<any>(undefined);
  const [params, setParams] = React.useState<any>(undefined);
  const [codeId, setCodeId] = React.useState<number>(-1);
  const { codeExam } = useParams<TypeParams>();
  const [studentId, setStudentId] = React.useState<number>(0);

  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const isMobileDevice = window.matchMedia('(max-width: 768px)').matches;

  const handleSubmit = (values: any) => {
    let param: any = { PTCode: codeExam };
    if (settings?.IS_HANDOUT) {
      param = { HandoutCode: codeExam };
    }
    query(
      settings?.IS_HANDOUT
        ? 'p_QUIZCITY_Academy_LinkHandout_CodeExam_CheckCode'
        : 'p_QUIZCITY_PTTest_CodeExam_CheckCode',
      param,
    ).then((res) => {
      if (res?.[0].status === 0) {
        setIsShowModalError(true);
      } else {
        setCodeId(res?.[0].codeId);
        setParams(res?.[0].jsonResult?.[0]);
        // Xoá câu trả lời được lưu trước khi làm bài
        dispatch(start(null));
        query(
          settings.IS_HANDOUT
            ? 'p_QUIZCITY_Academy_LinkHandout_CodeExam_Insert_ByUser'
            : 'p_QUIZCITY_PTTest_CodeExam_Update_ByUser',
          {
            Code: codeExam,
            ContactName: values.userName,
            ContactPhone: values.phone || '',
            ContactEmail: values.email || '',
            ContactCompany: values.company,
            ContactAddress: values.branch,
            ContactDepartment: values.department,
            ContactCode: values.id,
          },
        ).then((res) => {
          if (res?.[0].status === 1) {
            setIsViewPresentation(true);
            setStudentId(res?.[0]?.studentId);
          }
        });
      }
    });
  };

  return (
    <React.Fragment>
      {!isViewPresentation ? (
        <>
          <div
            style={{
              backgroundImage: `url(${bannerUrl}) `,
              backgroundSize: '100% 100%',
              minHeight: '100vh',
              padding: '50px 0px',
              margin: 0,
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Row className='justify-content-center' style={{ width: '100%', paddingBottom: 20, margin: 0 }}>
              <div style={{ width: 300, height: 60 }}>
                <img alt='English City' className='navbar-brand-img' src={logoUrl} />
              </div>
            </Row>
            <Row className='justify-content-center' style={{ margin: 0 }}>
              <Col style={{ maxWidth: 500, width: '95vw' }}>
                <Card className='mb-0 border-0 bg-secondary'>
                  <CardHeader className='pb-3 bg-transparent'>
                    <div className='mt-2 mb-1 text-center'>
                      <span style={{ fontSize: 24, fontWeight: 600, color: colors.font }}>
                        {settings.IS_PT ? 'THÔNG TIN DỰ THI' : 'THÔNG TIN HỌC SINH'}
                      </span>
                    </div>
                    <div className='text-center'>
                      <small>
                        <span className='tw-text-xl'>Điền thông tin vào form trước khi vào làm bài</span>
                      </small>
                    </div>
                  </CardHeader>
                  <CardBody className='px-lg-5 py-lg-5'>
                    <Form autoComplete='on' onFinish={handleSubmit} form={form}>
                      <Form.Item
                        hasFeedback
                        name='userName'
                        rules={[
                          { type: 'string', message: 'Tên không hợp lệ' },
                          {
                            whitespace: true,
                            message: 'Vui lòng nhập lại, không xuống dòng!',
                          },
                          {
                            required: true,
                            message: 'Vui lòng nhập tên!',
                          },
                        ]}
                      >
                        <Input
                          style={{
                            boxShadow: '0 1px 3px rgba(50, 50, 93, 0.15), 0 1px 0 rgba(0, 0, 0, 0.02)',
                            borderRadius: 3,
                          }}
                          allowClear
                          size='large'
                          prefix={<i style={{ color: 'rgba(0,0,0,.25)' }} className='fas fa-user' />}
                          placeholder='Họ và tên (*)'
                        />
                      </Form.Item>
                      <Form.Item
                        hasFeedback
                        name='email'
                        rules={[
                          { type: 'email', message: 'Email không hợp lệ' },
                          {
                            whitespace: true,
                            message: 'Vui lòng không xuống dòng!',
                          },
                          {
                            required: settings.IS_PT, // PT cần email, Handout thì không cần
                            message: 'Vui lòng nhập email!',
                          },
                        ]}
                      >
                        <Input
                          style={{
                            boxShadow: '0 1px 3px rgba(50, 50, 93, 0.15), 0 1px 0 rgba(0, 0, 0, 0.02)',
                            borderRadius: 3,
                          }}
                          allowClear
                          size='large'
                          prefix={<i style={{ color: 'rgba(0,0,0,.25)' }} className='fa fa-envelope' />}
                          placeholder={`Email${settings.IS_PT ? ' (*)' : ''}`}
                        />
                      </Form.Item>
                      <Form.Item
                        hasFeedback
                        name='phone'
                        rules={[
                          {
                            whitespace: true,
                            message: 'Vui lòng không xuống dòng!',
                          },
                        ]}
                      >
                        <Input
                          style={{
                            boxShadow: '0 1px 3px rgba(50, 50, 93, 0.15), 0 1px 0 rgba(0, 0, 0, 0.02)',
                            borderRadius: 3,
                          }}
                          allowClear
                          size='large'
                          prefix={<i style={{ color: 'rgba(0,0,0,.25)' }} className='fa fa-phone' />}
                          placeholder='Số điện thoại'
                        />
                      </Form.Item>
                      {!settings.IS_HANDOUT && (
                        <Form.Item name='code'>
                          <Input
                            disabled
                            style={{
                              boxShadow: '0 1px 3px rgba(50, 50, 93, 0.15), 0 1px 0 rgba(0, 0, 0, 0.02)',
                              borderRadius: 3,
                            }}
                            defaultValue={codeExam}
                            allowClear
                            size='large'
                            prefix={<i style={{ color: 'rgba(0,0,0,.25)' }} className='fa fa-lock' />}
                          />
                        </Form.Item>
                      )}
                      {settings.IS_PT && (
                        <>
                          <Form.Item
                            hasFeedback
                            name='company'
                            rules={[
                              {
                                whitespace: true,
                                message: 'Vui lòng không xuống dòng!',
                              },
                            ]}
                            maxLength={12}
                          >
                            <Input
                              style={{
                                boxShadow: '0 1px 3px rgba(50, 50, 93, 0.15), 0 1px 0 rgba(0, 0, 0, 0.02)',
                                borderRadius: 3,
                              }}
                              allowClear
                              size='large'
                              prefix={<BsFillBuildingsFill style={{ color: 'rgba(0,0,0,.25)' }} />}
                              placeholder='Tên công ty đang làm việc'
                            />
                          </Form.Item>
                          <Form.Item
                            hasFeedback
                            name='branch'
                            rules={[
                              {
                                whitespace: true,
                                message: 'Vui lòng không xuống dòng!',
                              },
                            ]}
                            maxLength={12}
                          >
                            <Input
                              style={{
                                boxShadow: '0 1px 3px rgba(50, 50, 93, 0.15), 0 1px 0 rgba(0, 0, 0, 0.02)',
                                borderRadius: 3,
                              }}
                              allowClear
                              size='large'
                              prefix={<AiOutlineBranches style={{ color: 'rgba(0,0,0,.25)' }} />}
                              placeholder='Chi nhánh/cơ sở (Nếu có)'
                            />
                          </Form.Item>
                          <Form.Item
                            hasFeedback
                            name='department'
                            rules={[
                              {
                                whitespace: true,
                                message: 'Vui lòng không xuống dòng!',
                              },
                            ]}
                            maxLength={12}
                          >
                            <Input
                              style={{
                                boxShadow: '0 1px 3px rgba(50, 50, 93, 0.15), 0 1px 0 rgba(0, 0, 0, 0.02)',
                                borderRadius: 3,
                              }}
                              allowClear
                              size='large'
                              prefix={<SiGoogleclassroom style={{ color: 'rgba(0,0,0,.25)' }} />}
                              placeholder='Bộ phận/phòng ban (Nếu có)'
                            />
                          </Form.Item>
                          <Form.Item
                            hasFeedback
                            name='id'
                            rules={[
                              {
                                whitespace: true,
                                message: 'Vui lòng không xuống dòng!',
                              },
                            ]}
                            maxLength={12}
                          >
                            <Input
                              style={{
                                boxShadow: '0 1px 3px rgba(50, 50, 93, 0.15), 0 1px 0 rgba(0, 0, 0, 0.02)',
                                borderRadius: 3,
                              }}
                              allowClear
                              size='large'
                              prefix={<BiCodeAlt style={{ color: 'rgba(0,0,0,.25)' }} />}
                              placeholder='Mã nhân viên'
                            />
                          </Form.Item>
                        </>
                      )}
                      <div className='text-center'>
                        <Button className='mt-2' type='primary' htmlType='submit' size='large' block>
                          {settings.IS_PT ? 'Bắt đầu bài thi' : 'Bắt đầu'}
                        </Button>
                      </div>
                    </Form>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </>
      ) : (
        <React.Fragment>
          {params && (
            <IndexPresentationAmesOnline codeId={codeId} codeExam={codeExam} params={params} studentId={studentId} />
          )}
        </React.Fragment>
      )}
      <ModalError isVisible={isShowModalError} href='https://toeic.ames.edu.vn/'></ModalError>
    </React.Fragment>
  );
};
export default LoginAmesOnline;
