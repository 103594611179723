import { DeleteOutlined, DownOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons';
import {
  Button,
  Col,
  Divider,
  Form,
  Input,
  Modal,
  Popconfirm,
  Row,
  Space,
  Tooltip,
  Tree,
  TreeSelect,
  notification,
} from 'antd';
import Card from 'components/Containers/Card';
import Container from 'components/Containers/Container';
import colors from 'constants/colors';
import { query, remove } from 'helpers/QueryHelper';
import moment from 'moment';
import DynamicTable from 'packages/DynamicForm/components/DynamicTable';
import React, { useEffect, useState } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { useSelector } from 'react-redux';
import {
  buildTreeDataTestTopics,
  buildTreeSelectTestTopics,
  filterTreeSelectTestTopics,
  testTopicColumns,
} from './tableColumn';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import './index.css';
import { formatDate, formatDateTimeQuery } from 'constants/FormatString';
import { quillFormats, quillModules } from 'constants/quill';

const TestTopics = () => {
  const loggedInUser = useSelector((state) => state.authReducer.loggedInUser);
  const [form] = Form.useForm();
  const [tableData, setTableData] = React.useState([]);
  const [submitLoading, setSubmitLoading] = React.useState(false);
  const [selectedRecord, setSelectedRecord] = React.useState({
    id: '',
    parentId: '',
    name: '',
    notes: '',
  });
  const [modalStatus, setModalStatus] = React.useState({
    open: false,
    title: '',
    typeAction: 'add',
    record: { id: '' },
  });

  //====== Phần comment là xử lý ColapsedAll/ ExpendAll khi nào cần dùng thì mở ra
  // colapse tree
  // const [expandedStatus, setExpandedStatus] = useState('expandAll');
  // const [expandedKeys, setExpandedKeys] = useState([]);

  const queryClient = useQueryClient();
  const KEY_SELECT_TESTTOPICS = 'p_QUIZCITY_Exam_Category_Select';
  const { data: testTopics, isLoading } = useQuery(KEY_SELECT_TESTTOPICS, () =>
    query('p_QUIZCITY_Exam_Category_Select'),
  );

  const treeSelect = buildTreeSelectTestTopics(testTopics);
  const treeFolder = buildTreeDataTestTopics(testTopics);
  const initialValues = {
    parentId: null,
    notes: '',
    name: '',
    testInfo: '<p><br/><p>',
  };
  const actionColumns = [
    {
      key: 'editInfomation',
      sort: false,
      title: 'Thao tác',
      width: '1%',
      render: (text, record) => {
        // record?.type lấy từ hàm buildTreeDataTestTopics
        return (
          <Space style={{ minWidth: 95 }}>
            <Tooltip placement='top' title={'Cập nhật'}>
              <Button
                key='edit-file'
                shape='circle'
                type='dashed'
                style={{ color: '#006EE6' }}
                icon={<EditOutlined />}
                onClick={() => {
                  handleShowModal('edit', record);
                }}
              />
            </Tooltip>
            {/* Không có chứa con */}
            {!(record?.childs?.length > 0) && (
              <>
                <Popconfirm
                  placement='topRight'
                  title={() => {
                    return (
                      <div>
                        <p style={{ fontSize: 14, fontWeight: 400 }}>Bạn có chắc chắn muốn xóa không ?</p>
                        <strong style={{ color: '#f5222d' }}>(Sau khi xóa dữ liệu sẽ không thể khổi phục)</strong>
                      </div>
                    );
                  }}
                  onConfirm={() => onDelete(record)}
                  okText='Đồng ý'
                  cancelText='Đóng'
                >
                  <Tooltip placement='top' title={'Xóa'}>
                    <Button
                      key='edit-file'
                      shape='circle'
                      type='dashed'
                      danger
                      style={{ color: '#f5222d' }}
                      icon={<DeleteOutlined />}
                    />
                  </Tooltip>
                </Popconfirm>
              </>
            )}
          </Space>
        );
      },
    },
  ];

  //Hiển thị Table khi dữ liệu được làm mới hoặc select một thư mục
  //Đưa vô useEfect để selectedRecord?.id thay đổi thì mới gọi lạionShowTable
  useEffect(() => {
    if (testTopics?.length > 0 && selectedRecord?.id) onShowTable(testTopics, selectedRecord.id);
  }, [testTopics, selectedRecord?.id]);

  const onNotification = (type, { message, description }) => {
    if (type === 'success') {
      notification.success({
        placement: 'top',
        duration: 3,
        message: message,
        description,
      });
    } else if (type === 'error') {
      notification.error({
        placement: 'top',
        duration: 3,
        message: message,
        description,
      });
    }
  };

  const handleShowModal = (typeAction, record) => {
    if (typeAction === 'add') {
      form.setFieldsValue({ parentId: record.id, testInfo: '', notes: '', name: '' });
    } else {
      form.setFieldsValue(record);
    }
    setModalStatus({
      open: true,
      title: typeAction === 'add' ? 'THÊM MỚI' : 'CẬP NHẬT THÔNG TIN',
      typeAction,
      record,
    });
  };

  // DELETE
  const onDelete = (record) => {
    const { id, ptCodeTotal } = record;
    if (ptCodeTotal) {
      onNotification('error', { message: 'Không thể xoá!', description: 'Đề test đã được sử dụng' });
    } else if (testTopics?.some((i) => i.parentId === id)) {
      onNotification('error', { message: 'Không thể xoá!', description: 'Đề test đang chứa cấp con' });
    } else {
      remove('Category', id, 'Exam', 'QUIZCITY')
        .then((res) => {
          onNotification('success', { message: 'Xoá dữ liệu thành công.' });
          queryClient.invalidateQueries(KEY_SELECT_TESTTOPICS);
        })
        .catch((error) => {
          onNotification('error', { message: 'Xoá dữ liệu thất bại.' });
          // console.log(error);
        });
    }
  };

  //INSERT / UPDATE
  const handleSubmit = (values) => {
    if (!submitLoading) {
      const { name, notes, parentId, testInfo } = values;
      if (testTopics.some((item) => item.name.toUpperCase() === name.toUpperCase()) && typeAction === 'add') {
        onNotification('error', { message: 'Tên đề test đã tồn tại!' });
      } else {
        setSubmitLoading(true);
        const typeSubmit = modalStatus.typeAction === 'add' ? 'Thêm mới' : 'Cập nhật';
        const { id, createdBy, createdDate } = modalStatus?.record;
        const parameter = {
          id: modalStatus.typeAction === 'add' ? '00000000-0000-0000-0000-000000000000' : id,
          name: name,
          createdBy: modalStatus.typeAction === 'add' ? loggedInUser.id : createdBy,
          notes: notes || '',
          testInfo: testInfo ?? '',
          parentId: parentId ? parentId : '00000000-0000-0000-0000-000000000000',
          CreatedDate: modalStatus.typeAction === 'add' ? moment().format(formatDateTimeQuery) : createdDate,
          ModifiedDate: moment().format(formatDateTimeQuery),
          ModifiedBy: loggedInUser.id,
        };
        query('p_QUIZCITY_Exam_Category_InsertUpdate', parameter)
          .then((res) => {
            onNotification('success', { message: typeSubmit + ' dữ liệu thành công.' });
            setModalStatus({ open: false });
            queryClient.invalidateQueries(KEY_SELECT_TESTTOPICS);
          })
          .catch((error) => {
            onNotification('error', { message: typeSubmit + ' dữ liệu thất bại.' });
            console.log(error);
          })
          .finally(() => setSubmitLoading(false));
      }
    }
  };

  //Table hiện các con của phần tử được chọn
  const onShowTable = (allData, parentId) => {
    if (allData?.length > 0 && parentId) {
      const displayTableData = allData.filter((shop) => shop.parentId === parentId);
      setTableData(displayTableData);
    } else setTableData([]);
  };

  const onSelect = (selectedKeys, info) => {
    const record = testTopics.find((item) => item.id === selectedKeys?.[0]);
    setSelectedRecord(record);
  };

  // // ColapseAll or ExpandAll tree folder
  // const handleExpandOrCollapseAll = (type) => {
  //   if (type === 'expandAll') {
  //     setExpandedStatus('collapseAll');
  //     const keys = testTopics?.map((i) => i?.id);
  //     setExpandedKeys(keys);
  //   } else if (type === 'collapseAll') {
  //     setExpandedStatus('expandAll');
  //     setExpandedKeys([]);
  //   }
  // };
  // //when select folder
  // const handleExpand = (expandedKeys) => {
  //   setExpandedKeys(expandedKeys);
  // };

  return (
    <div>
      <Container name='Danh mục đề test' parentName='Administration'>
        <Card title='Danh mục đề test'>
          <Row gutter={[12, 0]}>
            <Col lg={6}>
              <div
                style={{
                  border: 'dashed 1px',
                  borderColor: colors.border,
                  height: 'calc(100vh - 240px)',
                  padding: 12,
                }}
                className='rounded'
              >
                <Space size='small' wrap>
                  <Tooltip title='Tạo mới' placement='bottom'>
                    <Button
                      shape='circle'
                      type='dashed'
                      icon={<PlusOutlined />}
                      onClick={() => handleShowModal('add', selectedRecord)}
                    />
                  </Tooltip>
                  <Tooltip title='Cập nhật' placement='bottom'>
                    <Button
                      shape='circle'
                      type='dashed'
                      icon={<EditOutlined />}
                      style={{ alignSelf: 'flex-end' }}
                      onClick={() => {
                        handleShowModal('edit', selectedRecord);
                      }}
                    />
                  </Tooltip>
                  <Popconfirm
                    placement='bottomLeft'
                    title={() => {
                      return (
                        <div>
                          <p style={{ fontSize: 14, fontWeight: 400 }}>Bạn có chắc chắn muốn xóa không ?</p>
                          <strong style={{ color: '#f5222d' }}>(Sau khi xóa dữ liệu sẽ không thể khổi phục)</strong>
                        </div>
                      );
                    }}
                    onConfirm={() => onDelete(selectedRecord)}
                    okText='Đồng ý'
                    cancelText='Đóng'
                  >
                    <Tooltip title='Xóa' placement='bottom'>
                      <Button
                        shape='circle'
                        type='dashed'
                        danger
                        icon={<DeleteOutlined />}
                        style={{ alignSelf: 'flex-end' }}
                      />
                    </Tooltip>
                  </Popconfirm>
                </Space>
                <Divider style={{ margin: '12px 0' }} />

                <div
                  style={{
                    height: 'calc(100vh - 312px)',
                    overflowY: 'auto',
                    // display: 'flex',
                    // justifyContent: 'space-between',
                  }}
                >
                  <Tree.DirectoryTree
                    switcherIcon={<DownOutlined />}
                    onSelect={onSelect}
                    treeData={treeFolder}
                    // multiple={false}
                    // expandedKeys={expandedKeys} // colapseAll
                    // onExpand={handleExpand}
                  />

                  {/* <div style={{ padding: '0 5px' }}>
                    {expandedStatus === 'collapseAll' ? (
                      <MinusSquareOutlined onClick={() => handleExpandOrCollapseAll(expandedStatus)} />
                    ) : (
                      <PlusSquareOutlined onClick={() => handleExpandOrCollapseAll(expandedStatus)} />
                    )}
                  </div> */}
                </div>
              </div>
            </Col>
            <Col lg={18}>
              <div
                style={{
                  border: 'dashed 1px',
                  borderColor: colors.border,
                  height: 'calc(100vh - 240px)',
                  padding: 12,
                  overflow: 'auto',
                }}
                className='rounded'
              >
                <DynamicTable
                  rowKey={'id'}
                  loading={isLoading}
                  dataSource={tableData}
                  initialTableData={testTopicColumns}
                  extraColumns={actionColumns}
                  pagination={{
                    defaultPageSize: 10,
                    showSizeChanger: true,
                    pageSizeOptions: ['10', '20', '30', '50', '100'],
                    position: ['bottomRight'],
                  }}
                />
              </div>
            </Col>
          </Row>
        </Card>
      </Container>

      <Modal
        title={modalStatus.title}
        open={modalStatus.open}
        onCancel={() => setModalStatus({ open: false })}
        footer={[
          <Button onClick={() => setModalStatus({ open: false })}>Đóng</Button>,
          <Button
            type='primary'
            loading={submitLoading}
            onClick={() => {
              form.submit();
            }}
          >
            Lưu
          </Button>,
        ]}
      >
        <Form form={form} onFinish={handleSubmit} layout='vertical' initialValues={initialValues}>
          <Form.Item
            name='parentId'
            label='Cấp cha'
            // rules={[{ required: true, message: 'Chưa nhập' }]}
          >
            <TreeSelect
              showSearch
              treeData={[
                {
                  value: '00000000-0000-0000-0000-000000000000',
                  title: 'Không có cấp cha',
                  name: 'Không có cấp cha',
                  children: [],
                  level: 1,
                },
                ...treeSelect,
              ]}
              dropdownStyle={{ maxHeight: 800, overflow: 'auto' }}
              // treeDefaultExpandAll
              filterTreeNode={filterTreeSelectTestTopics}
              getPopupContainer={(triggerNode) => {
                return triggerNode.parentNode;
              }}
              allowClear
            />
          </Form.Item>
          <Form.Item name='name' label='Tên đề' rules={[{ required: true, message: 'Chưa nhập' }]}>
            <Input />
          </Form.Item>
          <Form.Item name='testInfo' label='Thông tin chi tiết'>
            <ReactQuill
            key='test-topic-editor'
              theme='snow' // Choose a theme (optional)
              style={{ marginBottom: 0 }}
              className='test-info-quill'
              // modules={quillModules}
              // formats={quillFormats}
            />
          </Form.Item>

          <Form.Item name='notes' label='Mô tả'>
            <Input />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default TestTopics;
