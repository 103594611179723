import Teacher_Classes_Details from 'pages/Teacher/Classes/ClassDetails';
import CodesExam from 'pages/Teacher/CodesExam/index';
import React from 'react';
// Icons
import Users from 'pages/Administration/Users/index';
import { MdDoneOutline, MdGroup as ClassIcon, MdClass } from 'react-icons/md';
import { FaCogs } from 'react-icons/fa';
import TestTopics from 'pages/Administration/TestTopics/index';
import ManageNotification from 'pages/Administration/ManageNotifications';
import ConfigSettings from 'pages/Administration/ConfigSettings';
import colors from 'constants/colors';
import IndexImportQuestion from 'pages/Administration/ImportQuestion';

import { SiGoogleclassroom } from 'react-icons/si';
import { HiOutlinePresentationChartBar } from 'react-icons/hi';
import { IoLibraryOutline } from 'react-icons/io5';
import { TbReportAnalytics } from 'react-icons/tb';
export default [
  {
    path: '/admin/users',
    name: 'Quản lý người dùng',
    miniName: 'C',
    icon: 'fa fa-user text-red',
    component: Users,
    layout: '/pt',
    exact: true,
    roles: ['administrator'],
    type: ['administrationRouters'],
    showInSidebar: true,
    sortOrder: 0,
  },
  {
    path: '/admin/import-question',
    name: 'Quản lý câu hỏi',
    miniName: 'C',
    icon: 'fas fa-question text-green',
    component: IndexImportQuestion,
    layout: '/pt',
    exact: true,
    roles: ['administrator'],
    type: ['administrationRouters'],
    showInSidebar: true,
    sortOrder: 1,
  },
  {
    path: '/admin/danh-muc-de-bai-test',
    name: 'Danh mục đề test',
    miniName: 'C',
    icon: <MdClass />,
    component: TestTopics,
    layout: '/pt',
    exact: true,
    roles: ['administrator'],
    type: ['administrationRouters'],
    showInSidebar: true,
    sortOrder: 2,
  },
  {
    path: '/admin/presentation/:presentationId/create',
    component: React.lazy(() => import('pages/Teacher/Presentations/Presentation')),
    layout: '/pt',
    exact: true,
    roles: ['administrator'],
    type: ['allowedRoutes'],
  },

  {
    path: '/admin/presentation/:presentationId/edit',
    component: React.lazy(() => import('pages/Teacher/Presentations/Presentation')),
    layout: '/pt',
    exact: true,
    roles: ['administrator'],
    type: ['allowedRoutes'],
  },

  {
    path: '/admin/classes/:classId/classwork/assignments/add',
    component: React.lazy(() => import('pages/Teacher/Classwork/Assignments/AddOrEdit')),
    layout: '/pt',
    exact: true,
    roles: ['administrator'],
    type: ['allowedRoutes'],
  },

  {
    path: '/admin/classes/:classId/classwork/assignments/:assignmentId/edit/:exerciseType',
    component: React.lazy(() => import('pages/Teacher/Classwork/Assignments/AddOrEdit')),
    layout: '/pt',
    exact: true,
    roles: ['administrator'],
    type: ['allowedRoutes'],
  },

  {
    path: '/admin/classes/:classId/classwork/assignments/:assignmentId/details/:exerciseType',
    component: React.lazy(() => import('pages/Teacher/Classwork/Assignments/Details')),
    layout: '/pt',
    exact: true,
    roles: ['administrator'],
    type: ['allowedRoutes'],
  },
  // {
  //   path: '/admin/configs',
  //   name: 'Thiết lập thông tin',
  //   component: ConfigSettings,
  //   icon: <FaCogs style={{ color: '#EC4899' }} />,
  //   layout: '/pt',
  //   exact: true,
  //   roles: ['administrator'],
  //   type: ['administrationRouters'],
  //   showInSidebar: true,
  //   sortOrder: 5,
  // },
];
